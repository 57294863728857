var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CDataTable',{attrs:{"items":_vm.displayedOrderItems,"fields":_vm.fields,"hover":"","border":""},scopedSlots:_vm._u([{key:"code",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[((item.hasPillar || item.hasFlange) && _vm.order.sputtering == 2)?_c('div',[_vm._v(_vm._s(item.code)+"-Zn")]):_c('div',[_vm._v(" "+_vm._s(item.code)+" ")])])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'platform_base_area')?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(_vm.calculatePlatformsArea())+" "+_vm._s(item.measure)+" ")]):(item.type === 'platform_coating_area')?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(_vm.calculatePlatformsArea())+" "+_vm._s(item.measure)+" ")]):(item.amount)?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(item.amount)+" "+_vm._s(item.measure)+" ")]):_c('div')])]}},{key:"equipment_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'platform_base_area')?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateVAT(_vm.order.platform_base_square_meter_price)))+" ")]):(item.type === 'platform_coating_area')?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateVAT(_vm.order.platform_coating_square_meter_price)))+" ")]):(item.equipment_price)?_c('div',{staticClass:"item-right text-nowrap"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateVAT(item.equipment_price)))+" ")]):_c('div')])]}},{key:"total_for_equipment",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'total_equipment_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentTotalCost()))+" ")]):_vm._e(),(item.type === 'platform_base_area')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateBaseTotalCost()))+" ")]):(item.type === 'platform_coating_area')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateCoatingTotalCost()))+" ")]):(item.type === 'total_quipment_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentTotalCost()))+" ")]):(item.amount)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentCost(item)))+" ")]):_c('div')])]}},{key:"installation_price",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'total_installation_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateInstallationTotalCost()))+" ")]):(item.installation_price)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateInstallationCost(item)))+" ")]):_c('div',{staticClass:"item-right"})])]}},{key:"discount_value",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.amount)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentItemDiscount(item)))+" ")]):_c('div',{staticClass:"item-right"})])]}},{key:"discount_for_equipment",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'total_equipment_cost_with_discount')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentTotalCostWithDiscount()))+" ")]):_vm._e(),(item.amount)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentItemCostWithDiscount(item)))+" ")]):_c('div',{staticClass:"item-right"})])]}},{key:"total_cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"td"},[(item.type === 'overheads')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateOverheads()))+" ")]):(item.type === 'equipment_painting_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentPaintigCost()))+" ")]):(item.type === 'delivery_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateDeliveryCost()))+" ")]):(item.type === 'total_equipment_and_installation_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateWithoutBaseTotalCost()))+" ")]):(item.type === 'platform_base_area')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateBaseTotalCost()))+" ")]):(item.type === 'platform_coating_area')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateCoatingTotalCost()))+" ")]):(item.type === 'total_platform_base_and_coating_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateBaseAndCoatingTotalCost()))+" ")]):(item.amount && !_vm.order.discount)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentAndInstallationCost(item)))+" ")]):(item.amount && _vm.order.discount)?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateEquipmentAndInstallationCostWithDiscount(item)))+" ")]):(item.type === 'total_cost')?_c('div',{staticClass:"item-right"},[_vm._v(" "+_vm._s(_vm.moneyFormat(_vm.calculateTotalCost()))+" ")]):(item.type === 'total_vat_cost' && _vm.order.has_vat)?_c('div',{staticClass:"item-right"},[_c('i',[_vm._v(_vm._s(_vm.moneyFormat((_vm.calculateTotalCost() / (1 + parseFloat(_vm.order.vat))) * parseFloat(_vm.order.vat))))])]):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }